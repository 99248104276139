<template>
  <v-app>
    <v-navigation-drawer app v-model="navShow" width="300" color="bg-light">
      <div class="row px-4 py-3 align-center no-gutters" style="height: 64px">
        <div class="col-auto">
          <v-icon large color="primary">mdi-account-circle</v-icon>
        </div>
        <div class="col-9 ps-2">
          <div class="text-truncate">{{ adminEmail }}</div>
        </div>
        <div class="col-1">
          <v-btn icon @click="$store.dispatch('admin/logout')">
            <v-icon color="primary">mdi-exit-to-app</v-icon>
          </v-btn>
        </div>
      </div>
      <v-list>
        <v-list-item :to="{ name: 'Dashboard' }">
          <v-list-item-icon>
            <v-icon>{{ "mdi-view-dashboard" }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Дашбоард</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item :to="{ name: 'Subjects' }">
          <v-list-item-icon>
            <v-icon>mdi-shape</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Предметы</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item :to="{ name: 'Users' }">
          <v-list-item-icon>
            <v-icon>mdi-account-multiple</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Пользователи</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item :to="{ name: 'Notifications' }">
          <v-list-item-icon>
            <v-icon>mdi-email</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Notifications</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item :to="{ name: 'Lessons' }">
          <v-list-item-icon>
            <v-icon>mdi-school</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Уроки</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item :to="{ name: 'DocumentVerifications' }">
          <v-list-item-icon>
            <v-icon>mdi-certificate-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>
              Верификация док-ов
              <v-badge
                v-if="docCount"
                color="accent"
                :content="`${docCount}`"
                class="ml-1"
              />
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item :to="{ name: 'Articles' }">
          <v-list-item-icon>
            <v-icon>mdi-post-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Блог</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-expansion-panels flat tile>
          <v-expansion-panel style="background-color: transparent">
            <v-expansion-panel-header class="pa-0 pe-3">
              <v-list-item>
                <v-list-item-icon>
                  <v-icon>mdi-book-open</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>Контент</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <div class="mx-n3">
                <v-list-item :to="{ name: 'ContentPages' }">
                  <v-list-item-icon>
                    <v-icon>mdi-page-layout-body</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>Страницы</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item :to="{ name: 'ContentFaq' }">
                  <v-list-item-icon>
                    <v-icon>mdi-frequently-asked-questions</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>Страница FAQ</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item :to="{ name: 'ContentMenuEdit' }">
                  <v-list-item-icon>
                    <v-icon>mdi-menu-open</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>Меню</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>

                <v-expansion-panels flat tile>
                  <v-expansion-panel style="background-color: transparent">
                    <v-expansion-panel-header class="pa-0 pe-3">
                      <v-list-item>
                        <v-list-item-icon>
                          <v-icon>mdi-book-open</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title>SEO</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-expansion-panel-header>

                    <v-expansion-panel-content>
                      <div class="mx-n3">
                        <v-list-item :to="{ name: 'SeoLinks' }">
                          <v-list-item-icon>
                            <v-icon>mdi-link-variant</v-icon>
                          </v-list-item-icon>
                          <v-list-item-content>
                            <v-list-item-title>Ссылки</v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item :to="{ name: 'SeoAdvantagesBlock' }">
                          <v-list-item-icon>
                            <v-icon>mdi-star</v-icon>
                          </v-list-item-icon>
                          <v-list-item-content>
                            <v-list-item-title>
                              Блок "Преимущества"
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </div>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <v-list-item :to="{ name: 'Referrals' }">
          <v-list-item-icon>
            <v-icon>mdi-handshake</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Реферальная программа</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-expansion-panels flat tile>
          <v-expansion-panel style="background-color: transparent">
            <v-expansion-panel-header class="pa-0 pe-3">
              <v-list-item>
                <v-list-item-icon>
                  <v-icon>mdi-handshake-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>
                    Партнеры
                    <v-badge
                      v-if="affiliatesApplicationsNew"
                      color="accent"
                      :content="`${affiliatesApplicationsNew}`"
                      class="ml-1"
                    />
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <div class="mx-n3">
                <v-list-item :to="{ name: 'Partners' }">
                  <v-list-item-icon>
                    <v-icon>mdi-poll</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>Программа</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item :to="{ name: 'PartnersRequest' }">
                  <v-list-item-icon>
                    <v-icon>mdi-account-question</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>Заявки</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <v-list-item :to="{ name: 'Feedback' }">
          <v-list-item-icon>
            <v-icon>mdi-forum</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>
              Обратная связь
              <v-badge
                v-if="feedbacksCount"
                color="accent"
                :content="`${feedbacksCount}`"
                class="ml-1"
              />
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item :to="{ name: 'Reports' }">
          <v-list-item-icon>
            <v-icon>mdi-alert-octagon</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>
              Репорты
              <v-badge
                v-if="!!reportTabCounter"
                color="accent"
                :content="reportTabCounter"
                class="ml-1"
              />
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item :to="{ name: 'BlackList' }">
          <v-list-item-icon>
            <v-icon>mdi-account-cancel</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Черный список</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item :to="{ name: 'Settings' }">
          <v-list-item-icon>
            <v-icon>mdi-cog</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Настройки</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar app color="white" elevation="1">
      <v-app-bar-nav-icon @click="navShow = !navShow" />
      <v-img
        src="@/assets/img/logo-dark.png"
        class="ml-4"
        max-width="100"
      ></v-img>
    </v-app-bar>

    <!-- Sizes your content based upon application components -->
    <v-main>
      <!-- Provides the application the proper gutter -->
      <v-container fluid>
        <!-- If using vue-router -->
        <router-view></router-view>
        <v-snackbar
          v-model="snackbar.show"
          :timeout="snackbar.timeout"
          :color="snackbar.color"
          elevation="3"
          min-width="0"
        >
          <strong>
            {{ snackbar.msg }}
          </strong>
          <template v-slot:action="{ attrs }">
            <v-btn
              icon
              color="white"
              v-bind="attrs"
              @click="$store.dispatch('closeSnackbar')"
            >
              <v-icon color="white">mdi-close</v-icon>
            </v-btn>
          </template>
        </v-snackbar>
      </v-container>
    </v-main>

    <!--    <v-footer app>-->
    <!--      -->
    <!--    </v-footer>-->
  </v-app>
</template>

<script>
import { mapGetters } from "vuex";
import { mapActions } from "vuex";

export default {
  name: "Home",
  data() {
    return {
      navShow: true,
      ordersDocCount: "",
    };
  },
  computed: {
    ...mapGetters({
      adminEmail: "admin/adminEmail",
      snackbar: "snackbarOptions",
      docCount: "docCount",
      updateTime: "updateTime",
      feedbacksCount: "feedbacksCount",
      affiliatesApplicationsNew: "affiliatesApplicationsNew",
      reportsPageCounter: "reportsPageCounter",
    }),
    reportTabCounter() {
      if (!this.reportsPageCounter) return 0;
      const countersArr = Object.values(this.reportsPageCounter);
      return countersArr.reduce((prev, next) => {
        return prev + next;
      }, 0);
    },
  },
  mounted() {
    this.getDocsCount();
    this.getFeedbacksCount();
    this.getAffiliatesApplicationsCount();
    this.setupReportsPageCounter();
    setInterval(() => {
      this.getDocsCount();
      this.getFeedbacksCount();
      this.getAffiliatesApplicationsCount();
      this.setupReportsPageCounter();
    }, this.updateTime);
  },
  methods: {
    ...mapActions({
      getDocsCount: "getDocsCount",
      getFeedbacksCount: "getFeedbacksCount",
      getAffiliatesApplicationsCount: "getAffiliatesApplicationsCount",
      setupReportsPageCounter: "setupReportsPageCounter",
    }),
  },
};
</script>
